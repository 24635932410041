export const UnitData =

// NOTE: To update data: 
    // 1. Download JSON from `unitData.js - ...` Google sheet. https://docs.google.com/spreadsheets/d/1Seg_C5KPRX3T8XodC4h1hIlL0lVpV9qEBFDaiQ7nndE/edit#gid=0
    // 2. Delete EVERYTHING below these comments
    // 3. Paste new data below
    // 4. Use `Find and replace` to replace the exact string within the brackets: {} with blank
    // 5. Do it again, but replace the exact string within the brackets: {} with blank
    // 6. Save, check, and commit the data

{
    "DEFAULT": {
        "id": "DEFAULT",
        "name": " ",
        "storeNeeded": true,
        "unitChildren": []
    },
    "TEST_UNIT": {
        "id": "TEST_UNIT",
        "name": "Test Unit",
        "shortName": "Testing",
        "motto": "Field testers",
        "shopifyUnitCollectionId": 268521537735,
        "storeNeeded": false
    },
    "Army": {
        "id": "Army",
        "name": "US Army",
        "shortName": "Army",
        "storeNeeded": true,
        "unitChildren": ["USARAK_FT_WAINWRIGHT", "MTN_10"]
    },
    "fort_bliss": {
        "id": "fort_bliss",
        "name": "3rd Brigade, 1st Armor Division (1st AD), and Fort Bliss",
        "shortName": "Brigade & Division & Base",
        "unitType": "Base",
        "location": "Fort Bliss",
        "storeNeeded": true
    },
    "AD_1_3ABCT_AR_1_77": {
        "id": "AD_1_3ABCT_AR_1_77",
        "name": "1st Battalion 77th Regiment 3rd ABCT 1st Armored Division",
        "shortName": "1-77 AR",
        "unitType": "Battalion",
        "insignia": "1-77_Ar_insignia.gif",
        "motto": "Steel Tigers",
        "location": "Fort Bliss",
        "shopifyUnitCollectionId": "164773494850",
        "storeNeeded": false,
        "unitChildren": ["AD_1_3ABCT_AR_1_77_HHC", "AD_1_3ABCT_AR_1_77_A_CO", "AD_1_3ABCT_AR_1_77_B_CO", "AD_1_3ABCT_AR_1_77_C_CO", "AD_1_3ABCT_AR_1_77_F_CO", "AD_1_3ABCT_AR_123_FSC"]
    },
    "AD_1_3ABCT_AR_1_77_HHC": {
        "id": "AD_1_3ABCT_AR_1_77_HHC",
        "name": "1-77 HHC 3rd ABCT 1st Armored Division",
        "shortName": "HHC 1-77 AR",
        "unitType": "Company",
        "insignia": "hhc-1-77-ar.jpeg",
        "motto": "Seek Strike Destroy!",
        "location": "Fort Bliss",
        "shopifyUnitCollectionId": 270306803911,
        "shopifyCivilianCollectionId": 270306836679,
        "storeNeeded": false,
        "childOf": "AD_1_3ABCT_AR_1_77"
    },
    "AD_1_3ABCT_AR_1_77_A_CO": {
        "id": "AD_1_3ABCT_AR_1_77_A_CO",
        "name": "A Company, 1-77, 3rd ABCT, 1st Armored Division",
        "shortName": "A Co 1-77 AR",
        "unitType": "Company",
        "insignia": "1-77_anger-co_logo.png",
        "motto": "Anger",
        "location": "Fort Bliss",
        "shopifyUnitCollectionId": 270942339271,
        "shopifyCivilianCollectionId": 271239315655,
        "storeNeeded": false,
        "childOf": "AD_1_3ABCT_AR_1_77"
    },
    "AD_1_3ABCT_AR_1_77_B_CO": {
        "id": "AD_1_3ABCT_AR_1_77_B_CO",
        "name": "B Company, 1-77, 3rd ABCT, 1st Armored Division",
        "shortName": "B Co 1-77 AR",
        "unitType": "Company",
        "insignia": "1-77_b-co.jpeg",
        "motto": "Butcher",
        "location": "Fort Bliss",
        "shopifyUnitCollectionId": "237229179079",
        "shopifyCivilianCollectionId": 277080834247,
        "storeNeeded": false,
        "childOf": "AD_1_3ABCT_AR_1_77"
    },
    "AD_1_3ABCT_AR_1_77_C_CO": {
        "id": "AD_1_3ABCT_AR_1_77_C_CO",
        "name": "C Company, 1-77, 3rd ABCT, 1st Armored Division",
        "shortName": "C Co 1-77 AR",
        "unitType": "Company",
        "insignia": "1-77_charlie-co_logo.png",
        "motto": "Charger",
        "location": "Fort Bliss",
        "shopifyUnitCollectionId": 272728555719,
        "shopifyCivilianCollectionId": 272719839431,
        "storeNeeded": false,
        "childOf": "AD_1_3ABCT_AR_1_77"
    },
    "AD_1_3ABCT_AR_1_77_F_CO": {
        "id": "AD_1_3ABCT_AR_1_77_F_CO",
        "name": "F Company, 1-77, 3rd ABCT, 1st Armored Division",
        "shortName": "F Co 1-77 AR",
        "unitType": "Company",
        "location": "Fort Bliss",
        "storeNeeded": true,
        "childOf": "AD_1_3ABCT_AR_1_77"
    },
    "AD_1_3ABCT_AR_123_FSC": {
        "id": "AD_1_3ABCT_AR_123_FSC",
        "name": "H Company, 1-77, 3rd ABCT, 1st Armored Division",
        "shortName": "H Co 123 FSC",
        "unitType": "Company",
        "insignia": "1-77_h-co.jpg",
        "motto": "Haymaker",
        "location": "Fort Bliss",
        "shopifyUnitCollectionId": 275623248071,
        "shopifyCivilianCollectionId": 275631374535,
        "storeNeeded": false,
        "childOf": "AD_1_3ABCT_AR_1_77"
    },
    "USARAK_FT_WAINWRIGHT": {
        "id": "USARAK_FT_WAINWRIGHT",
        "name": "USARAK: Brigade (1 SBCT) Division (25th IN) and Fort Wainwright",
        "shortName": "Brigade & Division & Base",
        "unitType": "Base",
        "insignia": "ArcticWarriors360x360.png",
        "location": "Fort Wainwright",
        "shopifyUnitCollectionId": "266662936775",
        "storeNeeded": false,
        "childOf": "Army",
        "unitChildren": ["CSSB_17th", "SBCT_1_25ID"]
    },
    "CSSB_17th": {
        "id": "CSSB_17th",
        "name": "17th Combat Sustainment Support Battalion",
        "unitType": "Battalion",
        "location": "Fort Wainwright",
        "storeNeeded": true,
        "childOf": "USARAK_FT_WAINWRIGHT"
    },
    "SBCT_1_25ID": {
        "id": "SBCT_1_25ID",
        "name": "1-25th SBCT",
        "shortName": "1-25th SBCT",
        "unitType": "Brigade",
        "location": "Fort Wainwright",
        "storeNeeded": true,
        "childOf": "USARAK_FT_WAINWRIGHT",
        "unitChildren": ["SBCT_1_25ID_IN_1_5", "SBCT_1_25ID_IN_1_24", "SBCT_1_25ID_FA_2_8"]
    },
    "SBCT_1_25ID_IN_1_5": {
        "id": "SBCT_1_25ID_IN_1_5",
        "name": "1st Battalion 5th Infantry Regiment",
        "shortName": "1-5 INF",
        "unitType": "Battalion",
        "location": "Fort Wainwright",
        "storeNeeded": true,
        "childOf": "SBCT_1_25ID"
    },
    "SBCT_1_25ID_IN_1_24": {
        "id": "SBCT_1_25ID_IN_1_24",
        "name": "1st Battalion 24th Infantry Regiment",
        "shortName": "1-24 INF",
        "unitType": "Battalion",
        "location": "Fort Wainwright",
        "storeNeeded": true,
        "childOf": "SBCT_1_25ID"
    },
    "SBCT_1_25ID_FA_2_8": {
        "id": "SBCT_1_25ID_FA_2_8",
        "name": "2nd Battalion 8th Field Artillery Regiment",
        "shortName": "2-8 FA 1/25 SBCT",
        "unitType": "Battalion",
        "insignia": "2-8-fa-unit-crest.jpeg",
        "motto": "Automatic",
        "location": "Fort Wainwright",
        "shopifyUnitCollectionId": "155773698114",
        "shopifyCivilianCollectionId": "266660643015",
        "storeNeeded": false,
        "childOf": "SBCT_1_25ID",
        "unitChildren": ["SBCT_1_25ID_FA_2_8_A_BTRY", "SBCT_1_25ID_FA_2_8_B_BTRY", "SBCT_1_25ID_FA_2_8_C_BTRY", "FA_2_8_D_BTRY", "FA_2_8_E_BTRY", "FA_2_8_FSC", "FA_2_8_HHB"]
    },
    "SBCT_1_25ID_FA_2_8_A_BTRY": {
        "id": "SBCT_1_25ID_FA_2_8_A_BTRY",
        "name": "Alpha BTRY",
        "shortName": "A BTRY 2-8 FA",
        "unitType": "Battery",
        "insignia": "avalanche-btry-180x180.jpg",
        "motto": "Avalanche",
        "location": "Fort Wainwright",
        "shopifyUnitCollectionId": "242530025671",
        "storeNeeded": false,
        "childOf": "SBCT_1_25ID_FA_2_8"
    },
    "SBCT_1_25ID_FA_2_8_B_BTRY": {
        "id": "SBCT_1_25ID_FA_2_8_B_BTRY",
        "name": "Bravo BTRY",
        "shortName": "B BTRY 2-8 FA",
        "unitType": "Battery",
        "motto": "Bulldogs",
        "location": "Fort Wainwright",
        "storeNeeded": true,
        "childOf": "SBCT_1_25ID_FA_2_8"
    },
    "SBCT_1_25ID_FA_2_8_C_BTRY": {
        "id": "SBCT_1_25ID_FA_2_8_C_BTRY",
        "name": "Charlie Battery 2-8 FA 1-25 SBCT",
        "shortName": "C BTRY 2-8 FA",
        "unitType": "Battery",
        "location": "Fort Wainwright",
        "storeNeeded": true,
        "childOf": "SBCT_1_25ID_FA_2_8",
        "unitChildren": ["SBCT_1_25ID_FA_2_8_C_BTRY_2PLT"]
    },
    "SBCT_1_25ID_FA_2_8_C_BTRY_2PLT": {
        "id": "SBCT_1_25ID_FA_2_8_C_BTRY_2PLT",
        "name": "2nd Platoon Charlie BTRY 2/8 FA 1 SBCT",
        "shortName": "2 PLT C BTRY 2/8FA 1SBCT",
        "unitType": "Platoon",
        "location": "Fort Wainwright",
        "shopifyUnitCollectionId": "160753877058",
        "storeNeeded": false,
        "childOf": "SBCT_1_25ID_FA_2_8_C_BTRY"
    },
    "FA_2_8_D_BTRY": {
        "id": "FA_2_8_D_BTRY",
        "name": "Delta BTRY",
        "shortName": "D BTRY 2-8 FA",
        "unitType": "Battery",
        "location": "Fort Wainwright",
        "storeNeeded": true,
        "childOf": "SBCT_1_25ID_FA_2_8"
    },
    "FA_2_8_E_BTRY": {
        "id": "FA_2_8_E_BTRY",
        "name": "E BTRY/2-8 FA 1 SBCT",
        "shortName": "E BTRY 2-8 FA",
        "unitType": "Battery",
        "location": "Fort Wainwright",
        "storeNeeded": true,
        "childOf": "SBCT_1_25ID_FA_2_8"
    },
    "FA_2_8_FSC": {
        "id": "FA_2_8_FSC",
        "name": "Forward Support Company/2-8 FA",
        "shortName": "FSC/2-8 FA",
        "unitType": "Company",
        "insignia": "fsc-2-8-FA-256x256.jpg",
        "location": "Fort Wainwright",
        "shopifyUnitCollectionId": "263896629447",
        "storeNeeded": false,
        "childOf": "SBCT_1_25ID_FA_2_8"
    },
    "FA_2_8_HHB": {
        "id": "FA_2_8_HHB",
        "name": "2/8 FA HHB",
        "shortName": "HHB 2-8 FA",
        "unitType": "Battery",
        "location": "Fort Wainwright",
        "shopifyUnitCollectionId": "164149002306",
        "storeNeeded": false,
        "childOf": "SBCT_1_25ID_FA_2_8"
    },
    "fort_drum": {
        "id": "fort_drum",
        "name": "Fort Drum",
        "shortName": "Ft Drum",
        "unitType": "Base",
        "location": "Fort Drum",
        "storeNeeded": true
    },
    "fort_drum_spouses_club": {
        "id": "fort_drum_spouses_club",
        "name": "North Country Spouses' Club",
        "shortName": "North Country Spouses' club",
        "unitType": "Club",
        "location": "Fort Drum",
        "shopifyUnitCollectionId": 272399499463,
        "storeNeeded": false
    },
    "MTN_10": {
        "id": "MTN_10",
        "name": "10th Mountain Division",
        "shortName": "10th Mountain",
        "unitType": "Division",
        "location": "Various",
        "storeNeeded": true,
        "childOf": "Army",
        "unitChildren": ["MTN_10_HHBN", "MTN_10_1BCT", "MTN_10_2BCT", "MTN_10_3BCT", "MTN_10_CAB", "MTN_10_DIVARTY", "MTN_10_SB"]
    },
    "MTN_10_HHBN": {
        "id": "MTN_10_HHBN",
        "name": "10th Mountain HHBn",
        "shortName": "10th MTN HHBn",
        "unitType": "Battalion",
        "location": "Fort Drum",
        "storeNeeded": true,
        "childOf": "MTN_10",
        "unitChildren": ["MTN_10_HHB_HHC", "MTN_10_HHB_OC", "MTN_10_HHB_ISC", "MTN_10_HHB_SC", "MTN_10_HHB_BAND"]
    },
    "MTN_10_1BCT": {
        "id": "MTN_10_1BCT",
        "name": "1st Brigade Combat Team, 10th MTN",
        "shortName": "1 BCT",
        "unitType": "Brigade Combat Team",
        "location": "Fort Drum",
        "storeNeeded": true,
        "childOf": "MTN_10",
        "unitChildren": ["MTN_10_1BCT_HHC", "MTN_10_1BCT_2_22", "MTN_10_1BCT_1_87", "MTN_10_1BCT_1_32", "MTN_10_1BCT_3_71", "MTN_10_1BCT_3_6", "MTN_10_1BCT_7EB", "MTN_10_1BCT_10BSB"]
    },
    "MTN_10_2BCT": {
        "id": "MTN_10_2BCT",
        "name": "2nd Brigade Combat Team, 10th MTN",
        "shortName": "2 BCT",
        "unitType": "Brigade Combat Team",
        "location": "Fort Drum",
        "shopifyUnitCollectionId": 163447767106,
        "storeNeeded": false,
        "childOf": "MTN_10",
        "unitChildren": ["MTN_10_2BCT_HHC", "MTN_10_2BCT_2_14", "MTN_10_2BCT_4_31", "MTN_10_2BCT_2_87", "MTN_10_2BCT_1_89", "MTN_10_2BCT_2_15", "MTN_10_2BCT_210BSB", "MTN_10_2BCT_STB"]
    },
    "MTN_10_3BCT": {
        "id": "MTN_10_3BCT",
        "name": "3rd Brigade Combat Team, 10th MTN",
        "shortName": "3 BCT",
        "unitType": "Brigade Combat Team",
        "location": "Fort Polk",
        "storeNeeded": true,
        "childOf": "MTN_10",
        "unitChildren": ["MTN_10_3BCT_HHC", "MTN_10_3BCT_2_4", "MTN_10_3BCT_2_30", "MTN_10_3BCT_2_2", "MTN_10_3BCT_3_89", "MTN_10_3BCT_5_25", "MTN_10_3BCT_317", "MTN_10_3BCT_710BSB"]
    },
    "MTN_10_CAB": {
        "id": "MTN_10_CAB",
        "name": "Combat Aviation Brigade, 10th MTN",
        "shortName": "10th MTN CAB",
        "unitType": "Brigade",
        "location": "Fort Drum",
        "storeNeeded": true,
        "childOf": "MTN_10",
        "unitChildren": ["MTN_10_CAB_HHC", "MTN_10_CAB_1_10", "MTN_10_CAB_6_6", "MTN_10_CAB_2_10", "MTN_10_CAB_3_10", "MTN_10_CAB_277ASB"]
    },
    "MTN_10_DIVARTY": {
        "id": "MTN_10_DIVARTY",
        "name": "10th MTN Division Artillery",
        "shortName": "10th MTN DIVARTY",
        "unitType": "Brigade",
        "location": "Fort Drum",
        "shopifyUnitCollectionId": 236214255815,
        "childOf": "MTN_10",
        "unitChildren": ["MTN_10_DIVARTY_HHB", "MTN_10_DIVARTY_3_6", "MTN_10_DIVARTY_2_15", "MTN_10_DIVARTY_5_25"]
    },
    "MTN_10_SB": {
        "id": "MTN_10_SB",
        "name": "10th Sustainment Brigade",
        "shortName": "10th SB",
        "unitType": "Brigade",
        "location": "Fort Drum",
        "storeNeeded": true,
        "childOf": "MTN_10",
        "unitChildren": ["MTN_10_SUS_STB", "MTN_10_SUS_548CSSB", "MTN_10_SUS_91MP", "MTN_10_SUS_63ORD"]
    },
    "MTN_10_HHB_HHC": {
        "id": "MTN_10_HHB_HHC",
        "name": "10th MTN HHB Headquarters and Support Company",
        "shortName": "10th MTN HHBn HHC",
        "unitType": "Company",
        "location": "Fort Drum",
        "storeNeeded": true,
        "childOf": "MTN_10_HHBN"
    },
    "MTN_10_HHB_OC": {
        "id": "MTN_10_HHB_OC",
        "name": "10th MTN HHB Operations Company",
        "shortName": "10th MTN HHBn Op Co",
        "unitType": "Company",
        "location": "Fort Drum",
        "storeNeeded": true,
        "childOf": "MTN_10_HHBN"
    },
    "MTN_10_HHB_ISC": {
        "id": "MTN_10_HHB_ISC",
        "name": "10th MTN HHB Intelligence and Sustainment Company",
        "shortName": "10th MTN ISC",
        "unitType": "Company",
        "location": "Fort Drum",
        "storeNeeded": true,
        "childOf": "MTN_10_HHBN"
    },
    "MTN_10_HHB_SC": {
        "id": "MTN_10_HHB_SC",
        "name": "10th MTN HHB Signal Company",
        "shortName": "10th MTN Signal Co",
        "unitType": "Company",
        "location": "Fort Drum",
        "storeNeeded": true,
        "childOf": "MTN_10_HHBN"
    },
    "MTN_10_HHB_BAND": {
        "id": "MTN_10_HHB_BAND",
        "name": "10th MTN HHB 10th Mountain Division Band",
        "shortName": "10th MTN Band",
        "unitType": "Company",
        "location": "Fort Drum",
        "storeNeeded": true,
        "childOf": "MTN_10_HHBN"
    },
    "MTN_10_1BCT_HHC": {
        "id": "MTN_10_1BCT_HHC",
        "name": "10th MTN, 1BCT, Headquarters and Headquarters Company",
        "shortName": "1st BCT HHC",
        "unitType": "Company",
        "location": "Fort Drum",
        "storeNeeded": true,
        "childOf": "MTN_10_1BCT"
    },
    "MTN_10_1BCT_2_22": {
        "id": "MTN_10_1BCT_2_22",
        "name": "10th MTN, 1BCT, 2nd Battalion, 22nd Infantry Regiment",
        "shortName": "2-22 INF",
        "unitType": "Battalion",
        "location": "Fort Drum",
        "shopifyUnitCollectionId": 236056936647,
        "storeNeeded": false,
        "childOf": "MTN_10_1BCT"
    },
    "MTN_10_1BCT_1_87": {
        "id": "MTN_10_1BCT_1_87",
        "name": "10th MTN, 1BCT, 1st Battalion, 87th Infantry Regiment",
        "shortName": "1-87 INF",
        "unitType": "Battalion",
        "location": "Fort Drum",
        "storeNeeded": true,
        "childOf": "MTN_10_1BCT"
    },
    "MTN_10_1BCT_1_32": {
        "id": "MTN_10_1BCT_1_32",
        "name": "10th MTN, 1BCT, 1st Battalion, 32nd Infantry Regiment",
        "shortName": "1-32 INF",
        "unitType": "Battalion",
        "location": "Fort Drum",
        "storeNeeded": true,
        "childOf": "MTN_10_1BCT"
    },
    "MTN_10_1BCT_3_71": {
        "id": "MTN_10_1BCT_3_71",
        "name": "10th MTN, 1BCT, 3rd Squadron, 71st Cavalry Regiment",
        "shortName": "3-71 CAV",
        "unitType": "Squadron",
        "motto": "Ghost Squadron",
        "location": "Fort Drum",
        "shopifyUnitCollectionId": 272335143111,
        "shopifyCivilianCollectionId": 272336519367,
        "storeNeeded": false,
        "childOf": "MTN_10_1BCT",
        "unitChildren": ["MTN_10_1BCT_3_71_A_TRP", "MTN_10_1BCT_3_71_B_TRP", "MTN_10_1BCT_3_71_C_TRP", "MTN_10_1BCT_3_71_D_TRP", "MTN_10_1BCT_3_71_HHT"]
    },
    "MTN_10_1BCT_3_71_A_TRP": {
        "id": "MTN_10_1BCT_3_71_A_TRP",
        "name": "Apocalypse TRP, 3-71 CAV",
        "shortName": "A Troop 3-71",
        "unitType": "Troop",
        "motto": "Apocalypse",
        "location": "Fort Drum",
        "shopifyUnitCollectionId": 157562863682,
        "storeNeeded": false,
        "childOf": "MTN_10_1BCT_3_71"
    },
    "MTN_10_1BCT_3_71_B_TRP": {
        "id": "MTN_10_1BCT_3_71_B_TRP",
        "name": "Banshee TRP, 3-71 CAV",
        "shortName": "B Troop 3-71",
        "unitType": "Troop",
        "motto": "Banshee",
        "location": "Fort Drum",
        "shopifyUnitCollectionId": 164250353730,
        "storeNeeded": false,
        "childOf": "MTN_10_1BCT_3_71"
    },
    "MTN_10_1BCT_3_71_C_TRP": {
        "id": "MTN_10_1BCT_3_71_C_TRP",
        "name": "Charlie TRP, 3-71 CAV",
        "shortName": "C Troop 3-71",
        "unitType": "Troop",
        "motto": "Charlie",
        "location": "Fort Drum",
        "shopifyUnitCollectionId": 264148844743,
        "storeNeeded": false,
        "childOf": "MTN_10_1BCT_3_71",
        "unitChildren": ["MTN_10_1BCT_3_71_C_TRP_1_PLT"]
    },
    "MTN_10_1BCT_3_71_C_TRP_1_PLT": {
        "id": "MTN_10_1BCT_3_71_C_TRP_1_PLT",
        "name": "1st PLT, Charlie TRP, 3-71 CAV",
        "shortName": "1st Platoon, C Troop",
        "unitType": "Platoon",
        "location": "Fort Drum",
        "shopifyUnitCollectionId": 163911270466,
        "storeNeeded": false,
        "childOf": "MTN_10_1BCT_3_71_C_TRP"
    },
    "MTN_10_1BCT_3_71_D_TRP": {
        "id": "MTN_10_1BCT_3_71_D_TRP",
        "name": "D TRP, 3-71 CAV",
        "shortName": "D Troop 3-71",
        "unitType": "Troop",
        "motto": "Diesel",
        "location": "Fort Drum",
        "shopifyUnitCollectionId": 260393140423,
        "storeNeeded": false,
        "childOf": "MTN_10_1BCT_3_71"
    },
    "MTN_10_1BCT_3_71_HHT": {
        "id": "MTN_10_1BCT_3_71_HHT",
        "name": "HHT, 3-71 CAV",
        "shortName": "HHT 3-71",
        "unitType": "Troop",
        "motto": "Havoc",
        "location": "Fort Drum",
        "shopifyUnitCollectionId": 157977280578,
        "storeNeeded": false,
        "childOf": "MTN_10_1BCT_3_71"
    },
    "MTN_10_1BCT_3_6": {
        "id": "MTN_10_1BCT_3_6",
        "name": "10th MTN, 1BCT, 3rd Battalion, 6th Field Artillery Regiment",
        "shortName": "3-6 FAR",
        "unitType": "Battalion",
        "location": "Fort Drum",
        "storeNeeded": true,
        "childOf": "MTN_10_1BCT"
    },
    "MTN_10_1BCT_7EB": {
        "id": "MTN_10_1BCT_7EB",
        "name": "10th MTN, 1BCT, 7th Engineer Battalion",
        "shortName": "7th Engineer Battalion",
        "unitType": "Battalion",
        "location": "Fort Drum",
        "storeNeeded": true,
        "childOf": "MTN_10_1BCT"
    },
    "MTN_10_1BCT_10BSB": {
        "id": "MTN_10_1BCT_10BSB",
        "name": "10th MTN, 1BCT, 10th Brigade Support Battalion",
        "shortName": "10th BSB",
        "unitType": "Battalion",
        "location": "Fort Drum",
        "storeNeeded": true,
        "childOf": "MTN_10_1BCT"
    },
    "MTN_10_2BCT_HHC": {
        "id": "MTN_10_2BCT_HHC",
        "name": "10th MTN, 2BCT, Headquarters and Headquarters Company",
        "shortName": "2nd BCT HHC",
        "unitType": "Company",
        "motto": "Havoc Company",
        "location": "Fort Drum",
        "storeNeeded": true,
        "childOf": "MTN_10_2BCT"
    },
    "MTN_10_2BCT_2_14": {
        "id": "MTN_10_2BCT_2_14",
        "name": "10th MTN, 2BCT, 2nd Battalion, 14th Infantry Regiment",
        "shortName": "2-14 IR",
        "unitType": "Battalion",
        "location": "Fort Drum",
        "storeNeeded": true,
        "childOf": "MTN_10_2BCT"
    },
    "MTN_10_2BCT_4_31": {
        "id": "MTN_10_2BCT_4_31",
        "name": "10th MTN, 2BCT, 4th Battalion, 31st Infantry Regiment",
        "shortName": "4-31 IR",
        "unitType": "Battalion",
        "location": "Fort Drum",
        "shopifyUnitCollectionId": 237859406023,
        "storeNeeded": false,
        "childOf": "MTN_10_2BCT"
    },
    "MTN_10_2BCT_2_87": {
        "id": "MTN_10_2BCT_2_87",
        "name": "10th MTN, 2BCT, 2nd Battalion, 87th Infantry Regiment",
        "shortName": "2-87 IR",
        "unitType": "Battalion",
        "location": "Fort Drum",
        "storeNeeded": true,
        "childOf": "MTN_10_2BCT"
    },
    "MTN_10_2BCT_1_89": {
        "id": "MTN_10_2BCT_1_89",
        "name": "10th MTN, 2BCT, 1st Squadron, 89th Cavalry Regiment",
        "shortName": "1-89 CAV",
        "unitType": "Battalion",
        "location": "Fort Drum",
        "storeNeeded": true,
        "childOf": "MTN_10_2BCT"
    },
    "MTN_10_2BCT_2_15": {
        "id": "MTN_10_2BCT_2_15",
        "name": "10th MTN, 2BCT, 2nd Battalion, 15th Field Artillery Regiment",
        "shortName": "2-15 FAR",
        "unitType": "Battalion",
        "location": "Fort Drum",
        "storeNeeded": true,
        "childOf": "MTN_10_2BCT"
    },
    "MTN_10_2BCT_210BSB": {
        "id": "MTN_10_2BCT_210BSB",
        "name": "10th MTN, 2BCT, 210th Brigade Support Battalion",
        "shortName": "210 BSB",
        "unitType": "Battalion",
        "location": "Fort Drum",
        "storeNeeded": true,
        "childOf": "MTN_10_2BCT"
    },
    "MTN_10_2BCT_STB": {
        "id": "MTN_10_2BCT_STB",
        "name": "10th MTN, 2BCT, Special Troops Battalion",
        "shortName": "Special Troops Battalion",
        "unitType": "Battalion",
        "location": "Fort Drum",
        "storeNeeded": true,
        "childOf": "MTN_10_2BCT"
    },
    "MTN_10_3BCT_HHC": {
        "id": "MTN_10_3BCT_HHC",
        "name": "10th MTN, 3BCT, Headquarters and Headquarters Company",
        "shortName": "3rd BCT HHC",
        "unitType": "Company",
        "location": "Fort Polk",
        "storeNeeded": true,
        "childOf": "MTN_10_3BCT"
    },
    "MTN_10_3BCT_2_4": {
        "id": "MTN_10_3BCT_2_4",
        "name": "10th MTN, 3BCT, 2nd Battalion, 4th Infantry Regiment",
        "shortName": "2-4 IR",
        "unitType": "Battalion",
        "location": "Fort Polk",
        "storeNeeded": true,
        "childOf": "MTN_10_3BCT"
    },
    "MTN_10_3BCT_2_30": {
        "id": "MTN_10_3BCT_2_30",
        "name": "10th MTN, 3BCT, 2nd Battalion, 30th Infantry Regiment",
        "shortName": "2-30 IR",
        "unitType": "Battalion",
        "location": "Fort Polk",
        "storeNeeded": true,
        "childOf": "MTN_10_3BCT"
    },
    "MTN_10_3BCT_2_2": {
        "id": "MTN_10_3BCT_2_2",
        "name": "10th MTN, 3BCT, 2nd Battalion, 2nd Infantry Regiment",
        "shortName": "2-2 IR",
        "unitType": "Battalion",
        "location": "Fort Polk",
        "storeNeeded": true,
        "childOf": "MTN_10_3BCT"
    },
    "MTN_10_3BCT_3_89": {
        "id": "MTN_10_3BCT_3_89",
        "name": "10th MTN, 3BCT, 3rd Squadron, 89th Cavalry Regiment",
        "shortName": "3-89 CAV",
        "unitType": "Battalion",
        "location": "Fort Polk",
        "storeNeeded": true,
        "childOf": "MTN_10_3BCT"
    },
    "MTN_10_3BCT_5_25": {
        "id": "MTN_10_3BCT_5_25",
        "name": "10th MTN, 3BCT, 5th Battalion, 25th Field Artillery Regiment",
        "shortName": "5-25 FAR",
        "unitType": "Battalion",
        "location": "Fort Polk",
        "storeNeeded": true,
        "childOf": "MTN_10_3BCT"
    },
    "MTN_10_3BCT_317": {
        "id": "MTN_10_3BCT_317",
        "name": "10th MTN, 3BCT, 317th Engineer Battalion",
        "shortName": "317 Engineer Battalion",
        "unitType": "Battalion",
        "location": "Fort Polk",
        "storeNeeded": true,
        "childOf": "MTN_10_3BCT"
    },
    "MTN_10_3BCT_710BSB": {
        "id": "MTN_10_3BCT_710BSB",
        "name": "10th MTN, 3BCT, 710th Brigade Support Battalion",
        "shortName": "710 BSB",
        "unitType": "Battalion",
        "location": "Fort Polk",
        "storeNeeded": true,
        "childOf": "MTN_10_3BCT"
    },
    "MTN_10_CAB_HHC": {
        "id": "MTN_10_CAB_HHC",
        "name": "10th MTN CAB, Headquarters and Headquarters Company",
        "shortName": "CAB HHC",
        "unitType": "Company",
        "location": "Fort Drum",
        "storeNeeded": true,
        "childOf": "MTN_10_CAB"
    },
    "MTN_10_CAB_1_10": {
        "id": "MTN_10_CAB_1_10",
        "name": "10th MTN CAB, 1st Battalion, 10th Aviation Regiment",
        "shortName": "2-10 AR",
        "unitType": "Battalion",
        "location": "Fort Drum",
        "storeNeeded": true,
        "childOf": "MTN_10_CAB"
    },
    "MTN_10_CAB_6_6": {
        "id": "MTN_10_CAB_6_6",
        "name": "10th MTN CAB, 6th Squadron, 6th Cavalry Regiment",
        "shortName": "6-6 CAV",
        "unitType": "Battalion",
        "location": "Fort Drum",
        "storeNeeded": true,
        "childOf": "MTN_10_CAB"
    },
    "MTN_10_CAB_2_10": {
        "id": "MTN_10_CAB_2_10",
        "name": "10th MTN CAB, 2nd Battalion, 10th Aviation Regiment",
        "shortName": "2-10 AR",
        "unitType": "Battalion",
        "location": "Fort Drum",
        "storeNeeded": true,
        "childOf": "MTN_10_CAB"
    },
    "MTN_10_CAB_3_10": {
        "id": "MTN_10_CAB_3_10",
        "name": "10th MTN CAB, 3rd Battalion, 10th Aviation Regiment",
        "shortName": "3-10 AR",
        "unitType": "Battalion",
        "location": "Fort Drum",
        "storeNeeded": true,
        "childOf": "MTN_10_CAB"
    },
    "MTN_10_CAB_277ASB": {
        "id": "MTN_10_CAB_277ASB",
        "name": "10th MTN CAB, 277th Aviation Support Battalion",
        "shortName": "277 ASB",
        "unitType": "Battalion",
        "location": "Fort Drum",
        "storeNeeded": true,
        "childOf": "MTN_10_CAB"
    },
    "MTN_10_DIVARTY_HHB": {
        "id": "MTN_10_DIVARTY_HHB",
        "name": "10th MTN DIVARTY, Headquarters and Headquarters Battery",
        "shortName": "DIVARTY HHB",
        "unitType": "Battery",
        "location": "Fort Drum",
        "storeNeeded": true,
        "childOf": "MTN_10_DIVARTY"
    },
    "MTN_10_DIVARTY_3_6": {
        "id": "MTN_10_DIVARTY_3_6",
        "name": "10th MTN DIVARTY, 3rd Battalion, 6th Field Artillery Regiment",
        "shortName": "3-6 FAR",
        "unitType": "Battalion",
        "location": "Fort Drum",
        "storeNeeded": true,
        "childOf": "MTN_10_DIVARTY"
    },
    "MTN_10_DIVARTY_2_15": {
        "id": "MTN_10_DIVARTY_2_15",
        "name": "10th MTN DIVARTY, 2nd Battalion, 15th Field Artillery Regiment",
        "shortName": "2-15 FAR",
        "unitType": "Battalion",
        "location": "Fort Drum",
        "storeNeeded": true,
        "childOf": "MTN_10_DIVARTY"
    },
    "MTN_10_DIVARTY_5_25": {
        "id": "MTN_10_DIVARTY_5_25",
        "name": "10th MTN DIVARTY, 5th Battalion, 25th Field Artillery Regiment",
        "shortName": "5-25 FAR",
        "unitType": "Battalion",
        "location": "Fort Polk",
        "storeNeeded": true,
        "childOf": "MTN_10_DIVARTY"
    },
    "MTN_10_SUS_STB": {
        "id": "MTN_10_SUS_STB",
        "name": "10th MTN SUS, Special Troops Battalion",
        "shortName": "SUS Special Troops Battalion",
        "unitType": "Battalion",
        "location": "Fort Drum",
        "storeNeeded": true,
        "childOf": "MTN_10_SB"
    },
    "MTN_10_SUS_548CSSB": {
        "id": "MTN_10_SUS_548CSSB",
        "name": "10th MTN SUS, 548th Combat Sustainment Support Battalion",
        "shortName": "548 CSSB",
        "unitType": "Battalion",
        "location": "Fort Drum",
        "storeNeeded": true,
        "childOf": "MTN_10_SB"
    },
    "MTN_10_SUS_91MP": {
        "id": "MTN_10_SUS_91MP",
        "name": "10th MTN SUS, 91st Military Police Battalion",
        "shortName": "91st MP Battalion",
        "unitType": "Battalion",
        "location": "Fort Drum",
        "storeNeeded": true,
        "childOf": "MTN_10_SB"
    },
    "MTN_10_SUS_63ORD": {
        "id": "MTN_10_SUS_63ORD",
        "name": "10th MTN SUS, 63rd Ordnance Battalion",
        "shortName": "63rd ORD",
        "unitType": "Battalion",
        "location": "Fort Drum",
        "storeNeeded": true,
        "childOf": "MTN_10_SB"
    },
    "ARMY_SPORTS": {
        "id": "ARMY_SPORTS",
        "name": "Army Sports",
        "shortName": "Army Sports",
        "unitType": "Sports",
        "location": "Army",
        "storeNeeded": true,
        "unitChildren": ["ARMY_WRESTLING"]
    },
    "ARMY_WRESTLING": {
        "id": "ARMY_WRESTLING",
        "name": "Army Wrestling",
        "shortName": "Army Wrestling",
        "unitType": "Sports",
        "location": "Army",
        "storeNeeded": true,
        "childOf": "ARMY_SPORTS"
    }
}