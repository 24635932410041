import React from "react";
import {Link} from "gatsby";
import {wainwrightUnitStructure} from "../data/wainwrightUnitStructure";
import {UnitData} from "../data/unitData";



const UnpackUnitChildren = ({ unitChild, inheritProps, index, recursive, showImages}) => {

	let nestedChildren;

	if (recursive){
	
		try{nestedChildren = (UnitData[unitChild].unitChildren || []).map(unitChild => {
	
			return <UnpackUnitChildren key={unitChild} showImages={showImages} unitChild={unitChild} type="child" inheritProps={inheritProps}	 />
			})
		} catch {return null}
	}

  return (

  	<>
  		{UnitData[unitChild] &&
		    <>
			    <div className={"link-container cell"} >
			    	<div className="grid-y">
			    		<Link id={unitChild + "-link"} className={GetClassName(unitChild, inheritProps, UnitData[unitChild])} to={"/stores/"+unitChild}>
		                    {(UnitData[unitChild].insignia && showImages) 
		                    	?	<div className="cell unit-link-image-container">
		                    			<img src={"/assets/images/unitInsiginias/"+UnitData[unitChild].insignia} />
		                    		</div> 
		                    	:	null
		                    }
							<p>{UnitData[unitChild].shortName}{UnitData[unitChild].motto ? (' "'+UnitData[unitChild].motto)+'"' : ""}</p>
						</Link>
					</div>
			    </div>
			    {nestedChildren}
		    </>
		}
    </>
  )
}

export default UnpackUnitChildren;


const GetClassName = (unitChild, inheritProps, unitData) => {
	// Function matches the URL path ID to the label and appends "Selected" to the current selected store
	let unitIdFromUrl, unitParentIdFromUrl, classNames;

	classNames = "link-label cell"


	try {
		unitIdFromUrl = inheritProps.params['*'].split("/").slice(-1)[0];
		unitParentIdFromUrl = inheritProps.params['*'].split("/")[0];

		// If this link has no actual store, display link with slightly muted color
		if(!unitData.shopifyUnitCollectionId && !unitData.shopifyCivilianCollectionId){
			classNames = classNames + " no-collection"
		}

	} catch(error){
		unitIdFromUrl = null;
		unitParentIdFromUrl = null;
	}



	if(unitIdFromUrl === unitChild){
		classNames = classNames + " selected";
		// return("link-label selected cell")
	} else if (unitParentIdFromUrl === unitChild){
		classNames = classNames + " child-selected";
		// return ("link-label child-selected cell")
	}
	// else {return("link-label cell")}


	return classNames;
}