import React from "react";
import {websiteContent} from '../data/websiteContent';


export const GetCurrentWebsite = () => {
	let cleanHostName = 'default';

	try { // This try block avoids breaking Gatsby's Rendering methods
		let currentHostname = window.location.hostname;

		if (currentHostname.indexOf("www") > -1 ){currentHostname = currentHostname.replace("www.", "")}

		if ( currentHostname.indexOf(".com") > -1 ){currentHostname = currentHostname.replace(".com", "")}

		cleanHostName = currentHostname; 

	} catch (e) {}
	
	let websiteContentId = ReturnWebsiteContentIdFromURL(cleanHostName)

    return(websiteContent[websiteContentId])
}


const ReturnWebsiteContentIdFromURL = (cleanedHostName) => { 
	// Takes an input of the domain name and returns the ID of the key website content (which is handled in websiteContent.js)
	let websiteContentIdToReturn;

	switch (cleanedHostName) {

		case "osucadreunitgear":
			websiteContentIdToReturn = "osu_cadre"
			break;

		case "fortdrumunitgear":
			websiteContentIdToReturn = "fort_drum"
			break;

		case "1-77unitgear":
			websiteContentIdToReturn = "ar_1_77"
			break;

		case "2-8faunitgear":
			websiteContentIdToReturn = "fa_2_8"
			break;

		case "3-71unitgear":
			websiteContentIdToReturn = "cav_3_71"
			break;

		case "fortwainwrightunitgear":
			websiteContentIdToReturn = "fort_wainwright"
			break;


		case "localhost": // UPDATE THIS TO CHANGE WHAT UNIT IS SHOWN ON localhost
			websiteContentIdToReturn = "ar_1_77"
			break;

		case "emblem-battalion-package-gatsby.onrender":
			websiteContentIdToReturn = "fort_drum_spouses_club"

			break;

		default:
			websiteContentIdToReturn = "default_build"
			break;
	}

	return websiteContentIdToReturn

}