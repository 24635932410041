import React from "react"
import {Link} from "gatsby";
import UnpackUnitChildren from '../functions/unpackUnitChildren';
import {UnitData} from "../data/unitData";
import {GetCurrentWebsite} from '../functions/getCurrentWebsite';
import "@fontsource/source-sans-pro/200.css"
import "@fontsource/source-sans-pro/400.css"
import "@fontsource/source-sans-pro/700.css"
import "@fontsource/source-sans-pro/900.css"




export default function HeaderFooter({ children }) {

	let currentWebsite = GetCurrentWebsite(); // Function to check domain and return templated info based on which domain a user has visited. (CURRENTLY IS HARD CODED)

    let topLevelUnitInfo = UnitData[currentWebsite.top_level_unit_id];
    let baseLevelUnitInfo = UnitData[currentWebsite.base_gear];
    if (!baseLevelUnitInfo){baseLevelUnitInfo = {}}

  return (

  	<>
		<div id="top-content-wrapper" >
			<div className="title-bar hide-for-medium" data-responsive-toggle="title-menu" data-hide-for="medium" style={{justifyContent: "space-between"}}>
				<Link className="show-for-small-only" to="/"><img src="/assets/images/emblem-logo.png" style={{maxWidth: "7rem"}}/></Link>
				<div style={{right: "0px"}}>
					<div className="title-bar-title" onClick={() => toggleMenu()}>Menu</div>
					<button className="menu-icon" type="button" data-toggle="title-menu" onClick={() => toggleMenu()}></button>

				</div>

		    </div>

		    <div className="top-bar show-for-medium" id="title-menu" style={{backgroundColor: "#1F2830"}}>
		      <div className="top-bar-left">
		       <Link className="show-for-medium" to="/"><img src="/assets/images/emblem-logo.png" style={{maxWidth: "7rem", paddingLeft: "1rem"}}/></Link>
		      </div>
		      <div className="top-bar-right">
		            <ul className="vertical medium-horizontal menu" style={{backgroundColor: "#1F2830"}}>
		                <li><Link to="/intake"><i className="fi-list"></i> <span>Fundraising on autopilot (create gear stores)</span></Link></li>
		                <li className="show-for-medium"><Link to="/stores"><i className="fi-list"></i> <span>Buy gear</span></Link></li>		                
		                <li className="show-for-small-only"><Link to="/stores"><i className="fi-list"></i> <span>{topLevelUnitInfo.shortName} gear</span></Link></li>		                
		                {topLevelUnitInfo.unitChildren && 
			                <>
			                	<ul id="unit-list-in-menu" className="show-for-small-only" >
									{currentWebsite.base_gear &&
										<li className="show-for-small-only"><Link id="base_gear-link" className="fi-list" to={"/stores/base_gear"}>Limited time {baseLevelUnitInfo.name} Gear</Link></li>
									}
								{(topLevelUnitInfo.unitChildren || []).map((unitChild, index) => {
									if (UnitData[unitChild].shopifyUnitCollectionID || UnitData[unitChild].shopifyCivilianCollectionID){}
										return (
											<li className="unit-list-item-in-menu" key={unitChild} style={{marginLeft: "10px"}} ><UnpackUnitChildren recursive index={index} key={unitChild} unitChild={unitChild} /></li>
											/*<li className="unit-list-item-in-menu" key={unitChild} style={{marginLeft: "10px"}} >
												{UnitData[unitChild].name}
											</li>*/

										)
								})}
								</ul>
		                  	</>
		              	}
		              

		            </ul>
		      </div>
		    </div>
					    

	        {children}  {/* This might be the cause of my problems with styling not working when stores are loaded directly. Try moving this below #top-content-wrapper */}

	    </div>{/*End of #top-content-wrapper*/}

		<div id="footer" className="grid-x grid-padding-x">
			<p className="cell">You're going to look good or I'll pay for it. - Mike Nemeth, CEO</p>
		</div>


    </>
  )
}

const toggleMenu = () => {
	document.getElementById('title-menu').classList.toggle("show-for-medium");
}