export const websiteContent = {
	"default_build":{
		"top_level_unit_id": "DEFAULT",
		"hero_image" : ""
	},
	"test_unit":{
		"top_level_unit_id": "TEST_UNIT"
	},
	"osu_cadre": {
		"top_level_unit_id": "Buckeye_ROTC_Cadre",
		"hero_image" : ""
	},
	"ar_1_77": {
		"top_level_unit_id": "AD_1_3ABCT_AR_1_77",
		// "base_gear": "fort_bliss",
		"hero_image" : "hero_image_1-77.jpg"
	},
	"fa_2_8": {
		"top_level_unit_id": "SBCT_1_25ID_FA_2_8",
		"base_gear": "USARAK_FT_WAINWRIGHT",
		"hero_image" : "hero_image_2-8fa.jpg"
	},
	"cav_3_71": {
		"top_level_unit_id": "MTN_10_1BCT_3_71",
		"hero_image" : ""
	},
	"fort_wainwright": {
		"top_level_unit_id": "SBCT_1_25ID_FA_2_8",
		"base_gear": "USARAK_FT_WAINWRIGHT",/* Typically the top level unit is the base-specific unit (EG the division) but sometimes it isn't. When it isn't, use this property to define the highest Base/Fort level Unit*/
		"hero_image" : ""
	},
	"fort_bliss": {
		"top_level_unit_id": "AD_1_3ABCT_AR_1_77",
		"base_gear": "fort_bliss",
		"hero_image" : ""
	},
	"fort_drum": {
		"top_level_unit_id": "MTN_10",
		"base_gear": "fort_drum",
		"hero_image" : ""
	},
	"fort_drum_spouses_club":{
		"top_level_unit_id": "fort_drum_spouses_club",
		//"hero_image" : "hero_image_2-8fa.jpg"
	}	
}